<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss">
.app {
  width: 100%;
  height: 100vh;
}
body, html {
  margin: 0;
  padding: 0;
  background: #0d1b2a;
outline: none;
}
</style>
